import type { ContentfulLocaleCode } from '~/lib/ContentfulService/model';

/**
 * Returns a display label for the current locale.
 * The locale can contain a language and a region (e.g. "en-US").
 *
 * @param locale The locale to use.
 * @param logger The logger to use.
 */
export function getLanguageLabel(
  locale?: string | ContentfulLocaleCode | undefined | null,
  logger = console
) {
  if (!locale) {
    return '';
  }

  let language;
  let country: string | undefined;

  if (locale === 'zh_Hans_CN' || locale === 'zh-CN') {
    language = 'zh-hans';
  } else if (locale === 'zh_Hant_TW' || locale === 'zh-Hant-TW') {
    language = 'zh-hant';
  } else if (locale.includes('_')) {
    [language = 'en', country] = locale.split('_');
    locale = language.concat(country);
  } else if (!locale.includes('-')) {
    // assume we only have a country - use 'en' by default
    language = 'en';
  } else {
    [language = 'en'] = locale.split('-');
  }

  try {
    const languageName = new Intl.DisplayNames([language], {
      type: 'language'
    });

    return `${languageName.of(language)}`;
  } catch (e) {
    logger.warn('could not get current language label.', locale, e);
  }

  return '';
}
