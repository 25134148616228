import type { ContentfulLocaleCode } from '~/lib/ContentfulService/model';

export const LOCALE_STORE_MAP: Record<ContentfulLocaleCode, string> = {
  en: 'ecommerce',
  'de-DE': 'ecommerce',
  'en-US': 'ecommerce_amer',
  'en-SG': 'ecommerce',
  'fr-FR': 'ecommerce',
  'it-IT': 'ecommerce',
  'ru-RU': 'ecommerce_russia',
  'ja-JP': 'ecommerce_japan',
  'ko-KR': 'ecommerce_korea',
  'zh-Hant-TW': 'ecommerce_apac',
  'zh-CN': 'ecommerce_china'
};

export const CONTENTFUL_LOCALE_DOWNLOAD_LOCALE_MAP: Record<
  ContentfulLocaleCode,
  string
> = {
  'de-DE': 'de_DE',
  'en-US': 'en_US',
  'ru-RU': 'ru_RU',
  'ja-JP': 'ja_JP',
  'ko-KR': 'ko_KR',
  'zh-Hant-TW': 'zh_Hant_TW',
  'zh-CN': 'zh_Hans_CN',
  en: 'en_US',
  // XXX: locales are not actually available in download index for now
  'fr-FR': 'en_US',
  'it-IT': 'en_US',
  'en-SG': 'en_US'
};

export const DOWNLOAD_LOCALE_CONTENTFUL_LOCALE_MAP: Record<
  string,
  ContentfulLocaleCode
> = {
  de_DE: 'de-DE',
  en_US: 'en-US',
  fr_FR: 'fr-FR',
  it_IT: 'it-IT',
  ru_RU: 'ru-RU',
  ja_JP: 'ja-JP',
  ko_KR: 'ko-KR',
  zh_Hant_TW: 'zh-Hant-TW',
  zh_Hans_CN: 'zh-CN'
};
