import type { ContentfulLocaleCode } from '~/lib/ContentfulService/model';
import { getLanguageLabel } from '~/utils/getLanguageLabel';
import { DOWNLOAD_LOCALE_CONTENTFUL_LOCALE_MAP } from '~/lib/DownloadService/constants';

const documentCategoryLabelPrefix = 'productDocumentDownloadTab.category.';

function useDocumentCategoryLabel(category: string, fallback?: string) {
  const { $textDictionary } = useNuxtApp();

  return (
    $textDictionary.value[documentCategoryLabelPrefix + category] ||
    fallback ||
    category
  );
}

export function useLanguageLabel(value: ContentfulLocaleCode): string {
  return useDocumentCategoryLabel(value, getLanguageLabel(value));
}

export function useFilterValueLabel(filterName: string, value: string): string {
  if (filterName === 'medialink.locale') {
    // we need to map the filter values as they are forwarded directly
    // from algolia
    return useLanguageLabel(DOWNLOAD_LOCALE_CONTENTFUL_LOCALE_MAP[value]);
  }
  if (filterName === 'downloadCategory.data') {
    return useDocumentCategoryLabel(value);
  }

  return value;
}
